import type { User } from "@/components/app-skeleton/app-skeleton.component";
import { Injectable, signal } from "@angular/core";
import type { RouterList } from "./user.service";

@Injectable({
	providedIn: "root",
})
export class StateService {
	userInfo = signal<User | null>(null);
	routeList = signal<RouterList | null>(null);
	selectedLevel = signal<number | null>(null);

	updateUserInfo(user: User) {
		this.userInfo.set(user);
	}

	updateSelectedLevel(level: number) {
		this.selectedLevel.set(level);
	}

	updateRouteList(routeList: RouterList) {
		this.routeList.set(routeList);
	}
}
